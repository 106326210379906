const OTP_METHOD_SMS = 1;
const OTP_METHOD_EMAIL = 2;
const OTP_METHOD_GOOGLE = 3;

const OTP_TYPE_VERIFY_REGISTER = 0; // Xác thực đăng ký
const OTP_TYPE_VERIFY_UNSUBSCRIBE = 1; // Hủy xác thực đăng ký
const OTP_TYPE_VERIFY_TRANSACTION = 2; // Xác thực GD
const OTP_TYPE_VERIFY_LOGIN = 3; // Xác thực đăng nhập
const OTP_TYPE_VERIFY_CANCEL_LOGIN = 4; // Hủy xác thực đăng nhập

const METHOD_SMS = 'SMS';
const METHOD_EMAIL = 'Email';
const METHOD_GoogleAuthenticator = 'GoogleAuthenticator';

const METHOD_BY_KEY = {
    [METHOD_SMS]: OTP_METHOD_SMS,
    [METHOD_EMAIL]: OTP_METHOD_EMAIL
};

const TITLE_BY_TYPE = {
    [OTP_TYPE_VERIFY_REGISTER]: 'Security.TitleSubscribe',
    [OTP_TYPE_VERIFY_UNSUBSCRIBE]: 'Security.TitleUnsubscribe',
    [OTP_TYPE_VERIFY_TRANSACTION]: 'Security.TitleVerifyOtp',
    [OTP_TYPE_VERIFY_CANCEL_LOGIN]: 'Security.TitleVerifyOtp',
};

const METHOD_NAME_BY_KEY = {
    [OTP_METHOD_SMS]: 'Security.Notify.MethodNamePhone',
    [OTP_METHOD_EMAIL]: 'Security.Notify.MethodNameEmail',
    [OTP_METHOD_GOOGLE]: 'Security.Notify.MethodName2Fa',
};

const OTP_INACTIVE = 0;
const OTP_ACTIVE = 1;

export default {
    OTP_METHOD_SMS,
    OTP_METHOD_EMAIL,
    OTP_TYPE_VERIFY_REGISTER,
    OTP_METHOD_GOOGLE,
    OTP_TYPE_VERIFY_UNSUBSCRIBE,
    OTP_TYPE_VERIFY_TRANSACTION,
    OTP_TYPE_VERIFY_LOGIN,
    OTP_TYPE_VERIFY_CANCEL_LOGIN,
    METHOD_SMS,
    METHOD_EMAIL,
    METHOD_GoogleAuthenticator,
    METHOD_BY_KEY,
    OTP_INACTIVE,
    OTP_ACTIVE,
    TITLE_BY_TYPE,
    METHOD_NAME_BY_KEY
}