const LOGIN_REQUEST = (state, user) => {
    state.status = { loggingIn: true};
    state.user = user;
  };

  const LOGIN_SUCCESS = (state, user) => {
      state.status = { loggedIn: true};
      state.user = user.userInfo;
  }

  const LOGIN_FAILURE = (state) => {
      state.status = {};
      state.user = null;
  }

  const LOGOUT = (state) => {
      state.status = {};
      state.user = null;
  }

  const REFRESH_TOKEN = (state, {access_token, refresh_token}) => {
    state.status.loggedIn = true;
    state.user = { ...state.user, accessToken: access_token, refreshToken: refresh_token };
  }

  const UPDATE_LIST_MEMBER = (state, param) => {
    state.user.list_member = { ...state.user.list_member, [param.id] : param.username };
  }

  const GET_LIST_MEMBER = (state, listMember) => {
    state.user.list_member = listMember;
  };

  const CHANGE_LANGUAGE_SUCCESS = (state, language) => {
    state.user.user.lang = language;
  };

  const CHANGE_MENU = (state, menu) => {
      state.user.menu = menu
      state.menu = menu
  }

  const CHANGE_ACCOUNT = (state, accounts) => {
      state.user.accounts = accounts
  }
  
  export default {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT,
    REFRESH_TOKEN,
    UPDATE_LIST_MEMBER,
    GET_LIST_MEMBER,
    CHANGE_LANGUAGE_SUCCESS,
    CHANGE_MENU,
    CHANGE_ACCOUNT
  };
  