<template>
  <a-modal ref="modalOtp" :width="(methodAuth == OtpConstant.OTP_METHOD_GOOGLE && typeAuth == OtpConstant.OTP_TYPE_VERIFY_REGISTER) ? 650 : 520" class="p-0 modal-register" v-model="visibleModal" @cancel="closeModal" :closable="false" :footer="null">
    <p class="register-title mb-0">{{ $t(OtpConstant.TITLE_BY_TYPE[typeAuth]) }}
      <span v-if="typeAuth != OtpConstant.OTP_TYPE_VERIFY_CANCEL_LOGIN" style="text-transform: uppercase;">{{ $t(OtpConstant.METHOD_NAME_BY_KEY[methodAuth]) }}</span>
    </p>
    <hr>
    <div class="p-3 register-body form-otp__body" style="width: 100%">
      <div class="popup-otp__main" v-if="typeAuth == OtpConstant.OTP_TYPE_VERIFY_TRANSACTION || typeAuth == OtpConstant.OTP_TYPE_VERIFY_CANCEL_LOGIN">
        <div class="text-center mb-3 sub-title__otp"> {{ $t('opt.method') }}  <span class="text-danger">*</span></div>
        <a-input-group compact style="width: 100%" v-if="isShowOptionMore">
          <a-select :size="'large'" :style="selectedItem == OtpConstant.OTP_METHOD_GOOGLE ? 'width: 100%' : 'width: 70%'" @change="handleMethodChange" v-model="selectedItem"  :placeholder="$t('opt.method')" :default-value="selectedDefault">
            <a-select-option class="slb-option" :value="item.method" v-for="(item, index) in methodOtp" :key="index">{{item.titleInput}}</a-select-option>
            <div slot="dropdownRender" slot-scope="menu">
              <v-nodes :vnodes="menu" />
              <a-divider style="margin: 0;" />
              <div class="option-more"
                  @mousedown="e => e.preventDefault()"
                  @click="redirectSecurity"
              >
              {{$t('opt.addMethod')}}
              </div>
            </div>
          </a-select>
          <a-button class="fw-bold" @click="getOtp(selectedItem)" type="primary" :disabled="disableBtnByMethod[selectedItem]" v-if="selectedItem !== OtpConstant.OTP_METHOD_GOOGLE" style=" width: 30% ;height: 40px;">{{ $t('Security.GetOTP') }}
            <span v-if="checkClick[selectedItem]">&nbsp;({{timeOutOTP[selectedItem]}}s)</span>
          </a-button>
        </a-input-group>
        <a-input-group v-else>
          <a-select :size="'large'" :style="selectedItem == OtpConstant.OTP_METHOD_GOOGLE ? 'width: 100%' : 'width: 70%'" @change="handleMethodChange" v-model="selectedItem" :placeholder="$t('opt.method')" :default-value="selectedDefault">
            <a-select-option class="slb-option" :value="item.method" v-for="(item, index) in methodOtp" :key="index">{{item.titleInput}}</a-select-option>
          </a-select>
          <a-button class="fw-bold" @click="getOtp(selectedItem)" type="primary" :disabled="disableBtnByMethod[selectedItem]" v-if="selectedItem !== OtpConstant.OTP_METHOD_GOOGLE" style=" width: 30% ;height: 40px;">{{ $t('Security.GetOTP') }}
            <span v-if="checkClick[selectedItem]">&nbsp;({{timeOutOTP[selectedItem]}}s)</span>
          </a-button>
        </a-input-group>
      </div>
      <div class="popup-otp__main" v-else-if="typeAuth == OtpConstant.OTP_TYPE_VERIFY_REGISTER && methodAuth == OtpConstant.OTP_METHOD_EMAIL">
        <div class="text-center mb-3 sub-title__otp"> {{ $t('opt.method') }}  <span class="text-danger">*</span></div>
        <a-input-group compact style="width: 100%">
          <a-input :size="'large'" style="width: 70%;" :max-length="255" v-model.trim="authEmail" :placeholder="$t('Security.PlaceHolderMail')" :disabled="disableBtnByMethod[methodAuth]"></a-input>
          <a-button class="fw-bold" @click="getOtp(methodAuth)" type="primary" :disabled="disableBtnByMethod[methodAuth]" style=" width: 30% ;height: 40px;">{{ $t('Security.GetOTP') }}
            <span v-if="checkClick[methodAuth]">&nbsp;({{timeOutOTP[methodAuth]}}s)</span>
          </a-button>
        </a-input-group>
      </div>

      <div class="popup-otp__main" v-else-if="typeAuth == OtpConstant.OTP_TYPE_VERIFY_REGISTER && methodAuth == OtpConstant.OTP_METHOD_SMS">
        <div class="text-center mb-3 sub-title__otp"> {{ $t('Security.PhoneNumber') }}  <span class="text-danger">*</span></div>
        <a-input-group compact style="width: 100%">
          <a-input 
            :size="'large'" 
            style="width: 70%;" 
            :max-length="12" 
            v-model.trim="authSms" 
            :placeholder="$t('Security.PlaceHolderPhone')" 
            @keypress="formatterPhone($event)"
            :disabled="disableBtnByMethod[methodAuth]"></a-input>
          <a-button class="fw-bold" @click="getOtp(methodAuth)" type="primary" :disabled="disableBtnByMethod[methodAuth]" style=" width: 30% ;height: 40px;">{{ $t('Security.GetOTP') }}
            <span v-if="checkClick[methodAuth]">&nbsp;({{timeOutOTP[methodAuth]}}s)</span>
          </a-button>
        </a-input-group>
      </div>

      <div class="popup-otp__main" v-else-if="typeAuth == OtpConstant.OTP_TYPE_VERIFY_REGISTER && methodAuth == OtpConstant.OTP_METHOD_GOOGLE">
        <div class="text-center mb-3 sub-title__2fa" v-html="$t('Security.GoogleAuthenticatorDescriptionModal')"></div>
        <div class="text-center">
          <img :src="google2FaInfo.url" alt="" width="150px" height="150px">
        </div>
        <div class="text-center mb-3">
          <p style="color: #262626;">{{ $t('Security.GoogleAuthenticatorKeyTitle') }}</p>
          <div class="d-flex justify-content-center align-items-center">
            <p class="key mb-0 fw-bold me-3 key-google2fa" @click="copyUrl">{{ google2FaInfo.key }}</p>
            <div class="d-flex align-items-center">
              <a-tooltip slot="addonAfter" :title="$t('Button.Copy')">
                <a-icon type="copy" @click="copyUrl" style="color:#3E97FF;"/>
              </a-tooltip>
            </div>
          </div>
        </div>
      </div>

      <div class="popup-otp__main" v-else-if="typeAuth == OtpConstant.OTP_TYPE_VERIFY_UNSUBSCRIBE && methodAuth == OtpConstant.OTP_METHOD_GOOGLE">
        <div class="text-center mb-3 sub-title__2fa" v-html="$t('Security.Google2FaCancel')"></div>
      </div>

      <div class="popup-otp__main" v-else-if="typeAuth == OtpConstant.OTP_TYPE_VERIFY_UNSUBSCRIBE && methodAuth == OtpConstant.OTP_METHOD_EMAIL">
        <div class="text-center mb-3 sub-title__2fa">{{ $t('Security.CancelTitle') }} email <b style="color: #262626;">{{ maskEmail(authEmail) }}</b> {{ $t('Security.ToUnsubscribe') }}</div>
        <div class="text-center">
          <a-button class="btn-get-otp-to-cancel" @click="getOtp(methodAuth)" :disabled="disableBtnByMethod[methodAuth]">
            {{ $t('Security.GetOTP') }} &nbsp; <span v-if="checkClick[methodAuth]">({{timeOutOTP[methodAuth]}}s)</span>
          </a-button>
        </div>
      </div>

      <div class="popup-otp__main" v-else-if="typeAuth == OtpConstant.OTP_TYPE_VERIFY_UNSUBSCRIBE && methodAuth == OtpConstant.OTP_METHOD_SMS">
        <div class="text-center mb-3 sub-title__2fa">{{ $t('Security.CancelTitle') }} {{ $t('Security.PhoneNumberNormal') }} <b style="color: #262626;">{{'*******' + authSms.slice(-3)}}</b> {{ $t('Security.ToUnsubscribe') }}</div>
        <div class="text-center">
          <a-button class="btn-get-otp-to-cancel" @click="getOtp(methodAuth)" :disabled="disableBtnByMethod[methodAuth]">
            {{ $t('Security.GetOTP') }} &nbsp; <span v-if="checkClick[methodAuth]">({{timeOutOTP[methodAuth]}}s)</span>
          </a-button>
        </div>
      </div>
      <div class="text-center mt-3 mb-3 sub-title__otp"> {{ $t('opt.authenticationCode') }}  <span class="text-danger">*</span></div>
      <div class="form-otp d-flex justify-content-center ">
        <div class="ap-otp-inputs d-flex justify-content-center align-items-center">
          <a-input
              v-for="(digit, index) in otpLength"
              :key="index"
              type="tel"
              class="ap-otp-input"
              :max-length="1"
              v-model="otp[index]"
              @input="onInput(index, $event.target.value)"
              @keydown="onKeyDown(index, $event)"
              @paste="onPaste(index, $event)"
              @keypress="formatterPhone($event)"
              ref="otpInputs"
            />
        </div>
      </div>
      <Recaptcha v-if="isEnableRecaptcha" ref="recaptcha" @verify="tokenRecaptcha"></Recaptcha>
    </div>
    <div class="text-center d-flex justify-content-center pb-2">
      <a-button v-if="(typeAuth == OtpConstant.OTP_TYPE_VERIFY_UNSUBSCRIBE || typeAuth == OtpConstant.OTP_TYPE_VERIFY_CANCEL_LOGIN)" @click="confirmOtp(methodAuth, typeAuth)" :disabled="isSubmitVisible" type="danger" class="btn-danger__otp fw-bold" style="margin-right: 15px">
        {{$t('Security.Cancel')}}
      </a-button>
      <a-button v-else @click="confirmOtp(methodAuth, typeAuth)" :disabled="isSubmitVisible" type="primary" class="btn-confirm__otp fw-bold" style="margin-right: 15px">
        <span v-if="methodAuth == OtpConstant.OTP_METHOD_GOOGLE || typeAuth == OtpConstant.OTP_TYPE_VERIFY_REGISTER">{{$t('Security.Register')}}</span>
        <span v-else>{{$t('opt.submit')}}</span>
      </a-button>
      <a-button class="btn-cancel__otp color-black" @click="closeModal">{{$t('opt.cancel')}}</a-button>
    </div>
  </a-modal>
</template>

<script>
import CallApi from "@/services/api.service";
import OtpConstant from "@/constants/OtpConstant";
import store from "@/store";
import Recaptcha from "./Recaptcha.vue";

export default {
components: {
    Recaptcha,
    VNodes: {
        functional: true,
        render: (h, ctx) => ctx.props.vnodes,
    },
},
name: "OtpForm",
  props: {
    visibleModal:{
      type: Boolean,
      default: false
    },
    typeAuth:{
      type: Number,
      default: null
    },
    methodAuth:{
      type: Number,
      default: null
    },
    accounts: {
      type: Object,
      default: () => ({}),
    },
    requestId:{
      type: String,
      default: null
    },
  },
  data() {
    return {
      code: null,
      otpLength: 6, // Length of the OTP
      otp: Array(6).fill(''), // Array to hold OTP values
      disabledSubmit: true,
      selectedItem: null,
      selectedDefault: null,
      authSms: null,
      authEmail: null,
      method: null,
      localRequestId: this.requestId,
      localVisibleModal: this.visibleModal,
      localAccounts: this.accounts,
      OtpConstant: OtpConstant,
      isSubmitVisible: true,
      timeOutOTP: {
        1: 60,
        2: 60
      },
      methodOtp: [
        {
          title: 'SMS',
          titleInput: 'OTP SMS',
          status: 1,
          method: OtpConstant.OTP_METHOD_SMS,
          phone: '',
          description: 'SMSDescription',
        },
        {
          title: 'Email',
          titleInput: 'OTP Email',
          status: 1,
          method: OtpConstant.OTP_METHOD_EMAIL,
          email: '',
          description: 'EmailDescription',
        },
        {
          title: 'GoogleAuthenticator',
          titleInput: 'Google Authentication',
          status: 1,
          method: OtpConstant.OTP_METHOD_GOOGLE,
          description: 'GoogleAuthenticatorDescription',
        }
      ],
      methodOtpLoaded: [],
      receivedData: null,
      auth:{},
      isShowOptionMore: true,
      disableBtnByMethod: {
        1: false,
        2: false,
        3: false
      },
      timerIdByMethod: {
        1: null,
        2: null,
      },
      timerId: null,
      checkClick : {
        1: false,
        2: false,
        3: false
      },
      google2FaInfo: {
        url: '',
        key: ''
      },
      isEnableAuth: false,
      isEnableRecaptcha: false,
      tokenRecaptchaVerify: null
    };
  },
  mounted() {
    if(this.localAccounts.email_enabled) {
      this.authEmail = this.localAccounts.auth_email;
    }

    if(this.localAccounts.sms_enabled) {
      this.authSms = this.localAccounts.auth_sms;
    }
    
    this.transformData(this.localAccounts);
    
    let dataLoaded = {
      'isEnableAuth': this.isEnableAuth,
      'methodOtp': this.methodOtpLoaded,
      'authLoginEnable': this.localAccounts.auth_login_enable
    }

    this.$emit('data-loaded', dataLoaded);

    this.getGoogle2FaInfo();
  },
  computed: {
    countdownTime() {
      if (this.selectedItem) {
        return this.timeOut[this.selectedItem];
      }
      return 0;
    },
    modalVisible: {
      get() {
        return this.visibleModal;
      },
      set(value) {
        this.$emit('update:visibleModal', value); // Emit an event to the parent
      }
    }
  },
  created() {

  },
  watch: {
    // Watch for changes in the requestId prop to keep the local data in sync
    requestId(newVal) {
      this.localRequestId = newVal;
    },
    visibleModal(newVal) {
      this.localVisibleModal = newVal;

      if(newVal) {
        let userLocalStorage = JSON.parse(localStorage.getItem('user'));

        this.transformData(userLocalStorage.accounts);
      }
      
    },
    localAccounts(newVal) {
      
      this.localAccounts = newVal;

      if(this.localAccounts.email_enabled && this.localAccounts.sms_enabled && this.localAccounts.two_2fa_enable) {
        this.isShowOptionMore = false;
      } else {
        this.isShowOptionMore = true;
      }
      
    },
    methodOtpLoaded(newVal) {
      this.methodOtpLoaded = newVal;
    },
    methodOtp(newVal) {
      this.methodOtp = newVal;
    },
    selectedItem(newVal) {
      this.selectedItem = newVal;
    },
    selectedDefault(newVal) {
      this.selectedDefault = newVal;
    }
  },
  // beforeDestroy() {
  //   clearInterval(this.timerIdByMethod);
  // },
  methods: {
    tokenRecaptcha(response) {
        this.tokenRecaptchaVerify = response;
    },
    formatterPhone($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 43) {
        $event.preventDefault();
      }
    },
    checkEmpty(param) { 
        return !param;
    },
    updateRequestId(newId) {
      this.localRequestId = newId;  // Modify the local data property instead of the prop directly
      this.$emit('update:requestId', newId);  // Emit an event to inform the parent about the change
    },
    getGoogle2FaInfo(){
      this.$store.dispatch("security/getGoogle2FAInfo")
          .then(response => {
            if (response.data){
              this.google2FaInfo.url = response.data.googleUrl;
              this.google2FaInfo.key = response.data.key;
            }
          });
    },
    copyUrl() {
      navigator.clipboard.writeText(this.google2FaInfo.key);
      this.showNotice(
          'success',
          this.$t('Notice.Title.Confirm'),
          this.$t('Notice.Message.CopySuccess')
      );
    },
    validateEmailGetOTP(data){
      let isValid = false;

      if(!this.checkEmpty(data)) {
        data = data.trim();
      }

      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      isValid = emailPattern.test(data);
      if (!isValid) {
        this.showNotice(
            'error',
            this.$t('Security.InvalidMail',
            ''
            )
        );
      }

      return isValid;
    },
    validateSmsGetOTP(data) {
      let isValid = false;

      if(!this.checkEmpty(data)) {
        data = data.trim();
      }

      const phonePattern = /^(0|(\+84)|84)[0-9]{8,9}$/;
      isValid = phonePattern.test(data);
      if (!isValid) {
        this.showNotice(
            'error',
            this.$t('Security.InvalidPhone'),
            '',
        );
      }

      return isValid;
    },
    maskEmail(email) {
      if(email) {
        const [username, domain] = email.split('@');
        const firstChar = username.charAt(0);
        const lastChar = username.charAt(username.length - 1);
        const middleChars = '*'.repeat(username.length - 2);

        return firstChar + middleChars + lastChar + '@' + domain;
      }

      return email;
    },
    redirectSecurity() {
      const routeData = this.$router.resolve({ name: 'AccountSecurity' });
      window.open(routeData.href, '_blank');
      this.closeModal();
    },
    countDownTimer (method) {
      if (this.timeOutOTP[method] > 0) {
        this.timerIdByMethod[method] = setTimeout(() => {
          this.timeOutOTP[method] -= 1;
          this.countDownTimer(method)
        }, 1000)
      }else{
        this.timeOutOTP[method] = 60;
        this.disableBtnByMethod[method] = false;
        this.checkClick[method] = false;
        clearTimeout(this.timerIdByMethod[method]);
      }
    },
    transformData(data) {

      if(data.two_2fa_enable) {
        this.selectedDefault = OtpConstant.OTP_METHOD_GOOGLE;
        this.selectedItem = OtpConstant.OTP_METHOD_GOOGLE;
      } else {
        if(data.email_enabled) {
          this.selectedDefault = OtpConstant.OTP_METHOD_EMAIL;
          this.selectedItem = OtpConstant.OTP_METHOD_EMAIL;
        }

        if(data.sms_enabled) {
          this.selectedDefault = OtpConstant.OTP_METHOD_SMS;
          this.selectedItem = OtpConstant.OTP_METHOD_SMS;
        }
      }

      this.methodOtpLoaded = [
        {
          title: 'GoogleAuthenticator',
          titleInput: 'Google Authentication',
          status: data.two_2fa_enable ? 1 : 0,
          method: OtpConstant.OTP_METHOD_GOOGLE,
          description: 'GoogleAuthenticatorDescription',
        },
        {
          title: 'SMS',
          titleInput: 'OTP SMS',
          status: data.sms_enabled ? 1 : 0,
          method: OtpConstant.OTP_METHOD_SMS,
          phone: data.auth_sms,
          description: 'SMSDescription',
        },
        {
          title: 'Email',
          titleInput: 'OTP Email',
          status: data.email_enabled ? 1 : 0,
          method: OtpConstant.OTP_METHOD_EMAIL,
          email: data.auth_email,
          description: 'EmailDescription',
        },
      ];

      this.methodOtpLoaded.sort((a, b) => b.status - a.status);

      this.methodOtp = this.methodOtpLoaded.filter(item => item.status !== 0);

      if(data.email_enabled && data.sms_enabled && data.two_2fa_enable) {
        this.isShowOptionMore = false;
      } else {
        this.isShowOptionMore = true;
      }

      if(data.email_enabled || data.sms_enabled || data.two_2fa_enable) {
        this.isEnableAuth = true;
      } else {
        this.isEnableAuth = false;
      }
    },
    confirmOtp(methodOtp, typeAuth){
        this.visible = false;

        let params = {},
            url = '';

      if(this.selectedItem && methodOtp == null) {
        methodOtp = this.selectedItem;
      }

      switch(methodOtp) {
        case OtpConstant.OTP_METHOD_SMS:
          params.method = methodOtp;
          params.type = typeAuth;
          url = "security/verifyOTP";
          
          break;
        case OtpConstant.OTP_METHOD_EMAIL:
          params.method = methodOtp;
          params.type = typeAuth;
          url = "security/verifyOTP";
          break;
        case OtpConstant.OTP_METHOD_GOOGLE:
          url = "security/enableGoogle2FA";
          params.method = methodOtp;
          params.type = typeAuth;
          if(typeAuth == OtpConstant.OTP_TYPE_VERIFY_UNSUBSCRIBE) {
            url = "security/disableGoogle2FA";
          }

          if(typeAuth == OtpConstant.OTP_TYPE_VERIFY_TRANSACTION || typeAuth == OtpConstant.OTP_TYPE_VERIFY_CANCEL_LOGIN) {
            url = "security/verifyOTP";
          }
          break;
      }
      
      params.request_id = this.localRequestId;
      params.otp = Object.keys(this.otp).map(key => `${this.otp[key]}`).join('') ?? '';

      params.token_recaptcha = this.tokenRecaptchaVerify;

      this.handleSignUp(params, url)
    },
    handleSignUp(params, url){
      this.$store.dispatch(url, params).then(response => {

        if (response.code === 0){
          
          this.transformData(response.data);
          
          let dataLoaded = {
            'isEnableAuth': this.isEnableAuth,
            'methodOtp': this.methodOtpLoaded,
            'authLoginEnable': response.data.auth_login_enable
          }

          this.stopTimer(params.method);

          if(params.type == OtpConstant.OTP_TYPE_VERIFY_TRANSACTION) {
            this.$emit('submit', params);
          } else {
            let msgSuccess = this.$t('Security.Notify.VerifySuccess', {'methodName': this.$t(OtpConstant.METHOD_NAME_BY_KEY[params.method])});

            if(params.type == OtpConstant.OTP_TYPE_VERIFY_UNSUBSCRIBE) {
              msgSuccess = this.$t('Security.Notify.CancelSuccess', {'methodName': this.$t(OtpConstant.METHOD_NAME_BY_KEY[params.method])});
            }

            this.localAccounts = response.data;

            let userLocalStorage = JSON.parse(localStorage.getItem('user'));

            userLocalStorage.accounts = this.localAccounts;
            localStorage.setItem('user', JSON.stringify(userLocalStorage));
            store.commit('authentications/CHANGE_ACCOUNT', this.localAccounts);

            this.showNotice(
                'success',
                msgSuccess,
                ''
            );

            this.$emit('data-loaded', dataLoaded);
            this.isEnableRecaptcha = false;
          }

          if(params.method == OtpConstant.OTP_METHOD_GOOGLE) {
            this.getGoogle2FaInfo();
          }
          
          this.closeModal();

        }else{

            if(response.errors == 'EnableRecaptcha') {
                
                this.isEnableRecaptcha = true;
                this.showNotice(
                    'error',
                    this.$t('Security.Notify.InvalidOTP'),
                    ''
                );
            } else {
                this.showNotice(
                    'error',
                    response.errors,
                    ''
                );
            }
            
        }
      });
    },
    stopTimer(method) {
      if (this.timerIdByMethod[method]) {
        
        clearTimeout(this.timerIdByMethod[method]);
        this.timerIdByMethod[method] = null;
      }

      this.timeOutOTP[method] = 60;
      this.disableBtnByMethod[method] = false;
      
    },
    handleMethodChange(value) {
      this.selectedItem = value
    },

    handleParam(type){
      let params = {};

      params.otp = Object.keys(this.otp).map(key => `${this.otp[key]}`).join('') ?? '';
      params.method = this.typeAuth;
      params.type = type;

      return params;
    },
    getOtp(method) {
      let typeGet = this.typeAuth;
      
      if (method) {

        let params = {
          'method': method,
          'type': typeGet,
        };

        if((method == OtpConstant.OTP_METHOD_EMAIL || method == OtpConstant.OTP_METHOD_SMS) && (typeGet == OtpConstant.OTP_TYPE_VERIFY_REGISTER || typeGet == OtpConstant.OTP_TYPE_VERIFY_TRANSACTION || typeGet == OtpConstant.OTP_TYPE_VERIFY_UNSUBSCRIBE || typeGet == OtpConstant.OTP_TYPE_VERIFY_CANCEL_LOGIN)) {
          
          if (method == OtpConstant.OTP_METHOD_SMS){
            
            let validSms = this.validateSmsGetOTP(this.authSms);

            if (!validSms){
              return;
            }

            params.phone = this.authSms;
            params.email = '';
          }
          else{
            let validEmail = this.validateEmailGetOTP(this.authEmail);

            if (!validEmail){
              return;
            }

            params.email = this.authEmail;
            params.phone = '';
          }

        }

        this.disableBtnByMethod[method] = true;

        CallApi.getOpt(params).then((response) => {
          if (response.code == 0) {
            this.checkClick[method] = true;
            this.countDownTimer(method);
            this.localRequestId = response.data.request_id;
            
            let msgSuccess = this.$t('Security.Notify.GetOtpEmailSuccess') + ' ' + this.maskEmail(params.email);

            if(method == OtpConstant.OTP_METHOD_SMS) {
              msgSuccess = this.$t('Security.Notify.GetOtpPhoneSuccess') + ' *******' + params.phone.slice(-3);
            }

            this.showNotice('success', msgSuccess, '');
          } else {
            this.showNotice('error', response.errors, '');
            this.disableBtnByMethod[method] = false;
          }
        });
      }
    },
    showNotice(type, title, content) {
      this.$notification[type]({
        message: title,
        description: content,
        duration: 5,
        style: {
          "margin-top": "50px"
        },
      });
    },
    closeModal() {
      this.resetLocalData()
      this.$emit('close-modal');
      this.localVisibleModal = false; // Update the local copy
      this.$emit('update:visibleModal', false); // Emit an event to inform the parent
      this.isEnableRecaptcha = false;
    },
    resetLocalData() {
      // this.stopTimer();
      this.otp = Array(6).fill('');
    },
    onInput(index, value) {
      if (value.length > 0 && index < this.otpLength - 1) {
        // Move to the next input if current input is filled
        this.$refs.otpInputs[index + 1].focus();
      }
      this.otp[index] = value;

      const hasNonEmptyValue = this.otp.some(value => !value);

      this.isSubmitVisible = true;

      if(!hasNonEmptyValue) {
        this.isSubmitVisible = false;
      }
      
    },
    onKeyDown(index, event) {
      if (event.key === 'Backspace' && index > 0 && this.otp[index] === '') {
        // Move to the previous input if the current input is empty and backspace is pressed
        this.$refs.otpInputs[index - 1].focus();
      }
    },
    onPaste(index, event) {
      event.preventDefault(); // Prevent the default paste action
      const clipboardData = event.clipboardData || window.clipboardData;
      const pastedData = clipboardData.getData('Text'); // Get pasted data as plain text

      // Only take digits that fit into the remaining fields
      const otpDigits = pastedData.split('').slice(0, this.otpLength - index);
      
      // Fill the OTP fields starting from the current index
      otpDigits.forEach((digit, i) => {
        if (i < this.otpLength - index) {
          this.$set(this.otp, index + i, digit);
          if (i < otpDigits.length - 1) {
            this.$refs.otpInputs[index + i + 1].focus(); // Move to the next input
          }
        }
      });

      const hasNonEmptyValue = this.otp.some(value => !value);

      this.isSubmitVisible = true;

      if(!hasNonEmptyValue) {
        this.isSubmitVisible = false;
      }
    },
    checkNumberInput(event) {
      const charCode = event.which ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.preventDefault();
      }
    },
  }
}
</script>

<style scoped>
#slb-otp{
  height: 36px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  line-height: 36px;
}
#slb-otp option{
  border-radius: 0;
}
div[aria-hidden="true"] {
  display: none;
}
.btn-get-otp-to-cancel{
  color: #1589F7 !important;
  border: none !important;
  box-shadow: none !important;
  font-weight: 600;
}
.ant-select-selection:active{
  border-color: #000000;
}
.option-more{
  line-height: 36px;
  color: #1589F7;
  padding: 0 12px;
  cursor: pointer;
}
.btn-cancel__otp{
  height: 36px;
  width: 90px;
}
.btn-confirm__otp:hover,
.btn-confirm__otp:hover:disabled,
.btn-confirm__otp:disabled,
.btn-confirm__otp{
  width: 115px;
  height: 36px;
  background-color: #1589F7;
  border-color: #1589F7;
  color: #FFFFFF;
}
.btn-danger__otp:hover,
.btn-danger__otp:hover:disabled,
.btn-danger__otp:disabled,
.btn-danger__otp{
  width: 120px;
  height: 36px;
  background-color: #DA251D;
  border-color: #DA251D;
  color: #FFFFFF;
}
.key-google2fa{
  font-size: 14px;
  color: #262626;
}
.key-google2fa:hover{
  cursor: pointer;
}
.form-otp__body .ant-input-lg,
.form-otp__body .ant-select-lg {
  font-size: 14px;
}
.form-otp .ap-otp-inputs .ap-otp-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-align: center;
  font-size: 16px;
  transition: all 0.15s ease;
  line-height: 24px;
  color: #262626;
  width: 30px;
  height: 36px;
  border-radius: 5px;
  margin: 0 8px;
  padding: 0;
  line-height: 0;
}
.register-title{
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #262626;
  text-align: center;
  margin-bottom: 24px;
}
.sub-title__otp{
  color: #262626;
  font-weight: 700;
  font-size: 13px;
}
.sub-title__2fa{
  color: #262626;
  font-size: 13px;
}
.register-body{
  padding: 0 20px;
}
hr{
  margin-top: 10px;
  margin-bottom: 0px;
}
#g-recaptcha{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
}
</style>