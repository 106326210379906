
import CallApi from '../../../services/api.service'

const getGoogle2FAInfo = async ({ commit }) => {
    const request  = await CallApi.getGoogle2FAInfo();
    if (request.data) {
        commit('GET_DATA_GOOGLE_2FA', request.data);
    }
    return request;
}

const enableGoogle2FA = async (_, params) => {
    const request = await CallApi.enableGoogle2FA(params);
    return request;
}

const disableGoogle2FA = async (_, params) => {
    const request = await CallApi.disableGoogle2FA(params);
    return request;
}

const createOTP = async (_, params) => {
    const request = await CallApi.createOTP(params);
    return request;
}

const verifyOTP = async (_, params) => {
    const request = await CallApi.verifyOTP(params);
    return request;
}

const authLoginEnable = async (_, params) => {
    const request  = await CallApi.authLoginEnable(params);
    return request;
}

export default {
    getGoogle2FAInfo,
    enableGoogle2FA,
    disableGoogle2FA,
    createOTP,
    verifyOTP,
    authLoginEnable
}