// import axios from 'axios'
import UserAPI from '../../../services/user.service';
import CallApi from '../../../services/api.service';

const fetchProfile = async ({ commit }) => {
    const user = await UserAPI.getMe();
    commit('FETCH_PROFILE', user);
    return user;
}

const getAllPermission = async () => {
    const response = await CallApi.getAllPermission();
    return response;
}

const getAllPermissionForUser = async (_, params) => {
    const response = await CallApi.getAllPermissionForUser(params);
    return response;
}

const editPermissionSubMerchant = async (_, params) => {
    const response = await CallApi.editPermissionSubMerchant(params);
    return response;
}

const getMenu = async ({commit}, merchantID) => {
    const request = await CallApi.getMenu(merchantID);
    if (request.data) {
        commit('GET_MENU', request.data);
    }
    return request;
}

const getMe = async () => {
    const response = await UserAPI.getMe();
    return response
}

const getDevice = async (_, params) => {
    const response = await UserAPI.getListDevice(params);
    return response
}
export default {
    fetchProfile,
    getAllPermission,
    getAllPermissionForUser,
    editPermissionSubMerchant,
    getMenu,
    getMe,
    getDevice,
}
