<template>
  <AppMasterVue>
    <PageTitle :title="title" :breadcrumbs="breadcrumbs"> </PageTitle>

    <section class="section security-section mb-0">
      <a-spin :spinning="loadingData" size="large">
        <a-card class="mb-30 shadow" style="min-height: 70vh;">
        <h1>{{ $t('Security.Title') }}</h1>
        <div class="mb-4">
          <div class="method-otp" v-for="(method, index) in methodOtp" :key="index">
            <a-row>
              <a-col class="mb-3" :xs="{ span: 24 }" :xl="{ span: 12 }">
                <div class="method-title mb-2">
                  <h3>{{ $t('Security.' + method.title) }}</h3>
                  <div v-if="method.status === 0" class="method-status">
                    <p>{{ $t('Security.NotRegistered') }}</p>
                  </div>
                  <div v-else class="method-status status-active">
                    <p>{{ $t('Security.Registered') }}</p>
                  </div>
                </div>
                <p style="font-style: italic;">{{ $t('Security.' + method.description) }}</p>
                <p v-if="method.method == OtpConstant.OTP_METHOD_SMS && method.status == OtpConstant.OTP_ACTIVE">{{ $t('Security.RegisteredPhoneNumber') }}: <span class="fw-bold">{{ maskPhoneNumber(method.phone) }}</span></p>
                <p v-else-if="method.method == OtpConstant.OTP_METHOD_EMAIL && method.status == OtpConstant.OTP_ACTIVE">{{ $t('Security.RegisteredEmail') }}: <span class="fw-bold">{{ maskEmail(method.email) }}</span></p>
              </a-col>
              <a-col :xs="{ span: 24 }" :xl="{ span: 3 }">
                <div v-if="method.method == OtpConstant.OTP_METHOD_SMS">
                  <a-button v-if="method.status == OtpConstant.OTP_ACTIVE" class="method-button" type="danger" @click="showModalRegister(method.status, method.method)" :disabled="isDisableBtnMethod">{{ $t('Security.CancelBtn') }}</a-button>
                  <a-button v-else class="method-button" type="primary" @click="showModalRegister(method.status, method.method)" :disabled="isDisableBtnMethod">{{ $t('Security.RegisterBtn') }}</a-button>
                </div>
                <div v-else-if="method.method == OtpConstant.OTP_METHOD_EMAIL">
                  <a-button v-if="method.status == OtpConstant.OTP_ACTIVE" class="method-button" type="danger" @click="showModalRegister(method.status, method.method)" :disabled="isDisableBtnMethod">{{ $t('Security.CancelBtn') }}</a-button>
                  <a-button v-else class="method-button" type="primary" @click="showModalRegister(method.status, method.method)" :disabled="isDisableBtnMethod">{{ $t('Security.RegisterBtn') }}</a-button>
                </div>
                <div v-else>
                  <a-button v-if="method.status == OtpConstant.OTP_ACTIVE" class="method-button" type="danger" @click="showModalRegister(method.status, method.method)" :disabled="isDisableBtnMethod">{{ $t('Security.CancelBtn') }}</a-button>
                  <a-button v-else class="method-button" type="primary" @click="showModalRegister(method.status, method.method)" :disabled="isDisableBtnMethod">{{ $t('Security.RegisterBtn') }}</a-button>
                </div>
              </a-col>
            </a-row>
          </div>
        </div>
        <h1 class="mb-1">{{ $t('Security.LoginSectionTitle') }}</h1>
        <p>{{ $t('Security.LoginSectionDescription') }}</p>
        <toggle-button :value="loginSecurityStatus" color="#82C7EB" :sync="true" @change="changeStatus($event)" :disabled="isEnableAuth"/>
          <h1 class="mb-1 mt-3">{{ $t('Security.UserDevice') }}</h1>
          <a-table :columns="columns" :data-source="listDevice" :row-key="record => record.id">
            <span slot="action" >


    </span>
            <template #customTitleNo>
              {{ $t('Security.DeviceName') }}
            </template>
            <template #lastLogin>
              {{ $t('Security.LastLogin') }}
            </template>
            <template slot="action" slot-scope="text, record">
              <a-button type="danger" @click="showModalDeleteDevice(record.user_id,record.device_id)">
                {{$t('Security.DeviceDelete')}}
              </a-button>
            </template>
            </a-table>
      </a-card>
      </a-spin>
    </section>
    <a-modal
        :visible="visibleDeleteDevice"
        :closable="false"
        :footer="null"
        @ok="handleOk"
        centered
        @cancel="handleCancel"
    >
      <h3 class="text-center">{{$t('Security.DeviceDelete')}}</h3>
      <p class="text-center">{{$t('Security.DeviceDeleteText')}}</p>
      <div class="button-container mt-4 text-center">
        <a-button type="danger" style="margin-right: 10px" @click="handleOk">{{$t('Security.DeviceDeleteButton')}}</a-button>
        <a-button @click="handleCancel">Cancel</a-button>
      </div>
    </a-modal>
    <OtpForm
      :accounts="user.accounts"
      :requestId="requestIdRegister"
      :visibleModal.sync="visibleModalRegister"
      @close-modal="handleModalRegisterClose"
      :typeAuth="typeAuth"
      :methodAuth="methodAuth"
      @data-loaded="handleDataLoaded">
    </OtpForm>
    <a-modal v-model="visibleModalEnableLogin" footer :closable="false"  id="modal-alert" :maskClosable="false">
        <div class="alert-content mb-2">
            <div class="alert-message mb-4">
              <h5 class="mb-4 fw-bold text-center" style="text-transform: uppercase;">{{ $t('Security.TitleEnableLogin') }}</h5>
              <p class="mb-2" style="font-size: 13px;">{{ $t('Security.TextEnableLogin') }}</p>
              <p class="mb-2" style="font-size: 13px;">{{ $t('Security.SubEnableLogin') }}</p>
            </div>
        </div>
        <div class="alert-footer text-center">
          <a-button class="me-3" type="primary" @click="enableAuthLogin">{{ $t('Security.BtnEnableLogin') }}</a-button>
          <a-button @click="closeAlertModal">{{ $t('Balance.ButtonTitle.close') }}</a-button>
        </div>
    </a-modal>
  </AppMasterVue>
</template>

<script>
import AppMasterVue from '../../../layout/AppMaster.vue'
import PageTitle from '@/components/PageTitle'
import OtpForm from "@/components/OtpTransaction/OtpForm";
import OtpConstant from "@/constants/OtpConstant";
import store from "@/store";
import {mapActions, mapGetters} from 'vuex';
import CallApi from "@/services/api.service";
import helper from "@/until/helper";
export default {
  name: 'AccountSecurity',
  components: {
    AppMasterVue,
    PageTitle,
    OtpForm
  },
  computed: {
    ...mapGetters({
      user: 'authentications/getUserLogin',
      profile: 'user/getProfile'
    }),
  },
  data() {
    return {
      title: "Sidebar.AccountSecurity",
      OtpConstant: OtpConstant,
      typeAuth: null,
      methodAuth: null,
      visibleModalEnableLogin: false,
      requestIdRegister: null,
      isEnableAuth: false,
      breadcrumbs: [
        {
          name: "Title.Home",
          routeName: 'Home',
          active: true,
        },
        {
          name: "Sidebar.AccountSecurity",
          routeName: 'AccountSecurity',
          active: true,
        },
      ],
      methodOtp: [],
      loginSecurityStatus: false,
      visibleModalRegister: false,
      dataRegister: {
        method: '',
        status: 0
      },
      loadingData: true,
      isDisableBtnMethod: false,
      listDevice : [],
      columns : [
        {
          slots: { title: "customTitleNo" },
          dataIndex: 'user_agent',
          key: 'user_agent',
        },
        {
          title: 'Id',
          dataIndex: 'device_id',
          key: 'device_id',
        },
        {
          slots: { title: "lastLogin" },
          dataIndex: 'last_login_at',
          key: 'last_login_at',
        },
        {
          title: 'Action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      visibleDeleteDevice : false,
      dataDeleteDevice : {
        user_id : null,
        device_id : null
      }
    }
  },
  mounted() {
    console.log(this.user)
    this.$store.dispatch("user/getDevice",{"username": this.user.user.username}).then(response => {
      if (response){
        this.listDevice = response.data
      }
    });
  },
  methods: {
    isset: helper.isset,
    ...mapActions('authentications', ['logout']),
    handleLogout() {
      this.logout();
    },
    enableAuthLogin() {
      let params = {};

      CallApi.authLoginEnable(params).then((response) => {
        if (response.code == 0) {
          this.showNotice('success', this.$t('Security.Notify.EnableLoginSuccess'), '');
          this.visibleModalEnableLogin = false;
          this.loginSecurityStatus = true;

          let userLocalStorage = JSON.parse(localStorage.getItem('user'));

          userLocalStorage.accounts = response.data;
          localStorage.setItem('user', JSON.stringify(userLocalStorage));
          store.commit('authentications/CHANGE_ACCOUNT', response.data);

        } else {
          this.showNotice('error', response.errors, '');
        }
      });
    },
    validateEmailGetOTP(data){
      let isValid = false;

      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      isValid = emailPattern.test(data);
      if (!isValid) {
        this.showNotice(
            'error',
            this.$t('Security.InvalidMail',
            ''
            )
        );
      }

      return isValid;
    },
    validateSmsGetOTP(data) {
      let isValid = false;

      const phonePattern = /^(0|(\+84)|84)[0-9]{8,9}$/;
      isValid = phonePattern.test(data);
      if (!isValid) {
        this.showNotice(
            'error',
            this.$t('Security.InvalidPhone'),
            '',
        );
      }

      return isValid;
    },
    handleDataLoaded(data) {
      this.loadingData = false;
      this.isEnableAuth = data.isEnableAuth ? false : true;
      this.methodOtp = data.methodOtp;
      this.loginSecurityStatus = data.authLoginEnable;
    },
    showModalRegister(status, method) {
      this.typeAuth = OtpConstant.OTP_TYPE_VERIFY_REGISTER;

      if(status == OtpConstant.OTP_INACTIVE) {
        this.visibleModalRegister = true;
        this.methodAuth = method;
      } else {
        this.typeAuth = OtpConstant.OTP_TYPE_VERIFY_UNSUBSCRIBE;
        this.methodAuth = method;
      }

      this.visibleModalRegister = true;
    },
    showNotice(type, title, content) {
      this.$notification[type]({
        message: title,
        description: content,
        duration: 5,
        style: {
          "margin-top": "50px"
        },
      });
    },
    showModalDeleteDevice(user_id,device_id){
      this.visibleDeleteDevice = true;
      this.dataDeleteDevice = {
        user_id : user_id,
        device_id : device_id
      }
    },
    handleOk() {
      this.visibleDeleteDevice = false;
      CallApi.logOutDevice(this.dataDeleteDevice).then((response) => {
        if (response) {
          this.handleLogout()
          this.showNotice('success', 'Thành công', response.data.original.message);
          this.$store.dispatch("user/getDevice").then(response => {
            if (response){
              this.listDevice = response.data
            }
          });
        } else {
          console.log(response)
        }
      });
    },
    handleCancel() {
      this.visibleDeleteDevice = false;
    },
    handleModalRegisterClose(){
        this.visibleModalRegister = false;
    },
    closeAlertModal() {
      this.visibleModalEnableLogin = false;
    },
    changeStatus(event){
      if (event.value){
        this.visibleModalEnableLogin = true;
      } else {
        this.typeAuth = OtpConstant.OTP_TYPE_VERIFY_CANCEL_LOGIN;
        this.visibleModalRegister = true;
      }
    },
    maskPhoneNumber(phoneNumber) {
      const lastThreeDigits = phoneNumber.slice(-3);
      const maskedPart = '*'.repeat(phoneNumber.length - 3);
      return maskedPart + lastThreeDigits;
    },
    maskEmail(email) {
      const [localPart, domain] = email.split('@');
      const firstChar = localPart.charAt(0);
      const lastChar = localPart.slice(-1);
      const maskedLocalPart = `${firstChar}*******${lastChar}`;
      return `${maskedLocalPart}@${domain}`;
    }
  }
}
</script>

<style>
  .security-section h1{
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 27px;
    color: #012970;
    font-weight: 600;
  }
  .security-section p{
    font-weight: 400;
    font-size: 13px;
    line-height: 19.5px;
    color: #000000;
    font-style: italic;
  }
  .method-otp h3{
    font-weight: 600;
    font-size: 13px;
    line-height: 19.5px;
    color: #000000;
    margin-bottom: 0;
  }
  .method-otp p{
    font-weight: 400;
    font-size: 13px;
    line-height: 19.5px;
    color: #000000;
    margin-bottom: 5px;
    font-style: normal;
  }
  .method-otp .method-title{
    display: flex;
    align-items: center;
  }
  .method-otp .method-status{
    display: flex;
    align-items: center;
    justify-content: center;
    background: #A0A4A8;
    width: 130px;
    height: 26px;
    margin-left: 20px;
    border-radius: 8px;
  }
  .method-otp .method-status p{
    font-weight: 600;
    font-size: 13px;
    color: #FFFFFF;
    margin-bottom: 0;
  }
  .status-active{
    background: #00C851 !important;
  }
  .method-otp .method-button{
    font-weight: 600;
    font-size: 13px;
    line-height: 100%;
    color: #FFFFFF;
    width: 100%;
    max-width: 115px;
    height: 30px;
    line-height: 28px;
    transition: all 0.2s ease;
  }
  .method-otp .method-button:hover{
    box-shadow: 0 1px 3px 0 rgba(60, 64, 67, .5), 0 4px 8px 3px rgba(60, 64, 67, .2) !important;
  }
  .method-otp .method-button.ant-btn-primary:active,
  .method-otp .method-button.ant-btn-primary:disabled,
  .method-otp .method-button.ant-btn-primary:focus,
  .method-otp .method-button.ant-btn-primary{
    color: #FFFFFF;
    background-color: #1589F7;
    border-color: #1589F7;
    box-shadow: none;
    text-shadow: none;
  }
  .method-otp .method-button.ant-btn-danger:active,
  .method-otp .method-button.ant-btn-danger:disabled,
  .method-otp .method-button.ant-btn-danger:focus,
  .method-otp .method-button.ant-btn-danger{
    background-color: #DA251D;
    border-color: #DA251D;
    box-shadow: none;
    text-shadow: none;
    color: #FFFFFF;
  }
  @media (max-width: 1200px) {
    .method-otp .method-title{
      justify-content: space-between;
    }
    .method-otp{
      border-bottom: 1px solid #B9B9B9;
      margin-bottom: 20px;
    }
    .method-otp .ant-row{
      padding-bottom: 20px;
    }
  }
</style>