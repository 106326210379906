<template>
  <a-modal
    id="request-modal"
    :title="$t('Security.RequestSetupTitle')"
    :visible="popupVisible"
    :cancel-text=null
    :ok-text="$t('Security.Register')"
    @ok="handleRedirectRegister">
    <p>{{ $t('Security.RequestSetupContent') }}</p>
  </a-modal>
</template>

<script>

export default {
  name: "PopupRegisterAuth",
  props: {
    popupVisible:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {
    handleRedirectRegister() {
      this.$router.push({ name: 'AccountSecurity' });
    }
  }
};
</script>